
import './config'
import './files'
import $ from 'jquery'
import 'owl.carousel2/dist/assets/owl.carousel.min.css'
import 'owl.carousel2/dist/assets/owl.theme.default.min.css'
window.$ = window.jQuery = $
require('owl.carousel2')

const getProductImages = () => {
  return window.files || []
}

const getContentDesc = () => {
  return window.config || {}
}

const initSubscribeForm = () => {
  const form = $('#subscribeForm')
  const email = form.find('#email')
  const submit = form.find('#submit')
  const overlay = form.find('#overlay')
  overlay.click(() => {
    form.addClass('active')
    email.focus()
  })
  const formStartSending = () => {
    form.addClass('sending')
    email.attr('disabled', true)
    submit.attr('disabled', true)
  }
  const formStopSending = () => {
    form.removeClass('sending active')
    email.removeAttr('disabled')
    submit.removeAttr('disabled')
    email.val('')
  }
  // send email to backend...
  form.on('submit', evt => {
    evt.preventDefault()
    evt.stopPropagation()
    const emailAddress = $(evt.target).serializeArray()[0].value
    formStartSending()
    $.ajax({
      url: 'https://us-central1-handleandkate.cloudfunctions.net/sendMail',
      data: { 'dest': emailAddress },
      success: () => {
        console.log('Email registered successfully.')
        overlay.html('Thank you for connecting with us')
        formStopSending()
      },
      error: () => {
        console.log('An error occurred.')
        overlay.html('An error occurred')
        formStopSending()
      }
    })
    return false
  })
}

const fileExists = filename => getProductImages().indexOf(filename) !== -1

const getProductFilename = (product, selectedFinish = null, selectedRose = null, index = 1) => {
  return `${product == null ? '' : product.id}_${selectedFinish == null ? 'f1' : selectedFinish.id}_${selectedRose == null ? '' : selectedRose.id}_${index}.png`
}

const filenameToUrl = filename => `/images/Products/${filename}`

const getProductSrc = (product, selectedFinish = null, selectedRose = null, index = 1) => {
  const filename = getProductFilename(product, selectedFinish, selectedRose, index)
  if (fileExists(filename)) {
    return filenameToUrl(filename)
  } else {
    console.log(`File not found: '${filename}'`)
    return '/images/static/noimage.png'
  }
}

function el(id) {
  return document.getElementById(id)
}

const setHtml = (id, html) => {
  el(id).innerHTML = html
}

const preload = src => {
  const tmp = new Image()
  tmp.src = src
}

function htmlElement(html, fn = null) {
  var template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  let el = null
  if (template.content && template.content.firstChild) {
    el = template.content.firstChild
  } else if (template.hasChildNodes()) {
    el = template.childNodes[0]
  }
  if (fn != null) {
    $(el).click(fn)
  }
  return el
}

function displayProduct() {
  const imageFiles = getProductImages()
  const cfg = getContentDesc()
  const urlParams = new URLSearchParams(window.location.search)
  const pid = urlParams.get('pid')
  const finishes = cfg.variants.find(v => v.name === 'finishes').values
  const roses = cfg.variants.find(v => v.name === 'roses').values
  const images = imageFiles.filter(f => f.startsWith(`${pid}_`))
  if (pid) {
    const product = cfg.products.find(p => p.id === pid)
    let selectedFinish = cfg.finishes[2]
    let selectedRose = cfg.roses[0]
    if (product) {
      const hasRose = product.variants.indexOf('roses') !== -1
      // set main image...
      const mainImageEl = $('.mainImage')
      const switchMainImageFn = evt => {
        console.log(`Switching to image - ${evt.target.src}`)
        mainImageEl.attr('src', evt.target.src)
      }

      // static thumb(s)...
      const thumbsEl = $('.thumbs')
      const thumbOne = getProductSrc(product, null, null, 1)
      mainImageEl.attr('src', encodeURI(thumbOne))
      thumbsEl.append(htmlElement(`<div class="displayHandle"><img title="Photo One" alt="Photo One" class="img cursor" src="${thumbOne}" /></div>`, switchMainImageFn))
      const filename = getProductFilename(product, null, null, 2)
      if (fileExists(filename)) {
        thumbsEl.append(htmlElement(`<div class="displayHandle"><img title="Photo Two" alt="Photo Two" class="img cursor" src="${filenameToUrl(filename)}" /></div>`, switchMainImageFn))
      }

      // show rose in thumbs...
      let roseThumbEl = null
      if (hasRose) {
        const thumbTwo = getProductSrc(product, null, selectedRose)
        roseThumbEl = htmlElement(`<div class="displayHandle"><img title="Photo of Rose" alt="Photo of Rose" class="img cursor roseThumb" src="${thumbTwo}" /></div>`, switchMainImageFn)
        thumbsEl.append(roseThumbEl)
        roseThumbEl = $('.roseThumb')
      }
      // show finish in thumbs...
      let finishEl = htmlElement(`<div class="displayHandle"><img title="Photo of Finish" alt="Photo of Finish" class="img cursor finishThumb" src="${selectedFinish.sample}" /></div>`, switchMainImageFn)
      thumbsEl.append(finishEl)
      finishEl = $('.finishThumb')
      let mainImageLabel = $('#mainImageLabel')
      // finishes
      // console.log(finishes)
      const finishesContainerEl = el('finishesEls')
      const finishEls = finishes.map(f => {
        const tmp = htmlElement(`<img title="${f.name}" alt="${f.name}" class="swatch cursor" src="${f.sample}" />`, evt => {
          selectedFinish = f
          // update thumb and main image...
          finishEl.attr('src', encodeURI(selectedFinish.sample))
          mainImageLabel.html(f.description)
          $('.owl-carousel').trigger('to.owl.carousel', 2)
          mainImageEl.attr('src', encodeURI(selectedFinish.sample))
          // add selected class...
          finishEls.forEach(el => $(el).removeClass('selected'))
          $(evt.target).addClass('selected')
        })
        // if this is the selected finish (on load)...
        if (f.id === selectedFinish.id) {
          $(tmp).addClass('selected')
        }
        $(finishesContainerEl).append(tmp)
        return tmp
      })
      // roses
      let roseEls = []
      if (hasRose) {
        const rosesContainerEl = el('rosesEls')
        roseEls = roses.map(r => {
          const tmp = htmlElement(`<a class="label cursor">${r.name}</a>`, evt => {
            selectedRose = r
            // update thumb and main image...
            const tmp = getProductSrc(product, null, selectedRose)
            roseThumbEl.attr('src', encodeURI(tmp))
            $('.owl-carousel').trigger('to.owl.carousel', 1)
            mainImageEl.attr('src', encodeURI(tmp))
            // add selected class...
            roseEls.forEach(el => $(el).removeClass('selected'))
            $(evt.target).addClass('selected')
          })
          // if this is the selected rose (on load)...
          if (r.id === selectedRose.id) {
            $(tmp).addClass('selected')
          }
          $(rosesContainerEl).append(tmp)
          return tmp
        })
      } else {
        $('#roses').addClass('d-none')
      }
      // name, cost, description
      const fields = ['name', 'costDetail', 'description']
      setTimeout(() => {
        fields.forEach(key => {
          setHtml(key, product[key])
        })
      }, 0)
      // enquire now
      $('#enquirenow').click(() => {
        console.log('opening mail link...')
        const subject = encodeURIComponent(`Handle and Kate Enquiry`)
        const body = encodeURIComponent(`Hi Handle and Kate,\n\nI would like to enquire about the '${product.name}' ${product.type === 'pull' ? '' : `with '${selectedRose.name}' rose`} in ${selectedFinish.name} finish.\n\nPlease contact me via return email or my best contact number is below\n\nINSERT PHONE NUMBER:\nINSERT ADDITIONAL QUESTIONS OR SUPPORT REQUEST:\n\nThanks`)
        window.location.href = `mailto:kate@handleandkate.com?subject=${subject}&body=${body}`
      })
      // Order now link
      const orderlinkEl = $('.displayOrderNow')
      product.orderLinks.forEach(link => {
        const tmp = htmlElement(`<div><a href=${link.link} target="_blank">${link.name}</a><div>`)
        $(orderlinkEl).append(tmp)
      })

      // tech specs
      $('#techSpecsLink').attr('href', `/downloads/Technical Specifications/${product.name} Specs.pdf`)
      // hero image
      el('hero').src = `/images/Product Hero/${product.name}.png`
      // preload all images (roses)...
      el('blurbbody').textContent =
        product.type === 'hook' ?
          `Handle & Kate hooks are waterproof and stain resistant promoting installation throughout your home.`
          : `Handle & Kate hardware is waterproof and stain resistant promoting installation throughout your home.`
      // preload all images (roses)...
      images.forEach(i => preload(`/images/Products/${i}`))
    } else {
      console.log(`Could not find product with id '${pid}'`)
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  $(document).ready(() => {
    initSubscribeForm()
    displayProduct()
    $('.owl-carousel').owlCarousel({
      items: 1,
      nav: false,
      dots: true
    })
  })
})
